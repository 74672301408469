import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M8.53341 25.3334L6.66675 23.4667L14.1334 16.0001L6.66675 8.53341L8.53341 6.66675L16.0001 14.1334L23.4667 6.66675L25.3334 8.53341L17.8667 16.0001L25.3334 23.4667L23.4667 25.3334L16.0001 17.8667L8.53341 25.3334Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }